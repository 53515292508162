
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import VColumnSelect from '@/components/shared/table/VColumnSelect.vue';
import articleHeaders from './headers';
import typeSortByPrice from './sortByPrice';
import typeSortByVisibility from './sortByVisibility';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VTable from '@/components/shared/table/VTable.vue';
import { Article } from '@/interfaces/models/Article';
import { Category } from '@/interfaces/models/Category';
import { Permission } from '@/enums/Permission';
import _ from 'lodash';
import { Venue } from '@/interfaces/models/Venue';
import { namespace } from 'vuex-class';
import VItemSelect from '@/components/shared/table/VItemSelect.vue';
import { mixins } from 'vue-class-component';
import { EditArticle as EditArticleMixin } from '@/mixins/EditArticle';
import Filter from '@/interfaces/api/Filter';
import cookie from 'js-cookie';
import { RoleType } from '../../enums/RoleType';
import { Tag } from '@/interfaces/models/Tag';
import { decimalAdjust, storageAvailable, parseJSON } from '@/util/helper';
import { TuRRoleType } from '@/enums/TuRRoleType';
import { SubwayRoleType } from '@/enums/SubwayRoleType';
import i18n from '@/i18n';
import FormatPriceInObject from '@/mixins/FormatPriceInObject';
import { ExternalOrderProvider } from '@/enums/ExternalOrderProvider';
import { ExternalOrderProvidersArticleSettings } from '@/interfaces/models/ExternalOrderProvidersArticleSettings';

const foodcard = namespace('foodcard');

type ExternalOrderProviderKeys = keyof ExternalOrderProvidersArticleSettings;

@Component({
  components: { VItemSelect, VTable, VColumnSelect },
})
export default class ArticleTable extends mixins(EditArticleMixin, FormatPriceInObject) {
  @Prop({ type: Array, required: true }) public items!: Article[];
  @Prop({ type: Number, required: true }) public total!: number;
  @Prop({ type: Array, required: true }) public categories!: Category[];
  @Prop({ type: Array, required: false }) public tags!: Tag[];
  @foodcard.State('articleFilter') public articleFilter!: Filter;

  readonly tableKey: string = 'articleTableColumns';
  readonly externalOrderProvider = ExternalOrderProvider;

  public $refs!: {
    vTable: InstanceType<typeof VTable> & { vTable: any };
    vColumnSelect: InstanceType<typeof VColumnSelect> & { resetSelectedHeaders: () => any };
  };

  public sourceTypePrice = typeSortByPrice;
  public sourceTypeVisibility = typeSortByVisibility;
  public search = '';
  public number = '';
  public recentArticleHeaders = this.getRecentArticleHeaders();
  public sourceHeaders = this.accessibleHeaders;
  public headers: ColumnHeader[] = (this.accessibleHeaders as ColumnHeader[]).filter((c: ColumnHeader) => c.default);
  public category: string | null = '';
  public editDialog: any = {
    isVisible: false,
    value: null,
    name: '',
    original: null,
    cell: null,
  };

  public mounted() {
    if (!this.venue) return;
    this.serializeHeaders();
  }

  public async onHeadersSelection(headers: ColumnHeader[]) {
    this.headers = headers;
    await this.storeRecentArticleHeaders();
  }

  private serializeHeaders() {
    const shouldIncludeHeader = (headerValue: string): boolean => {
      if (
        (headerValue === 'uberEatsTakeawayEnabled' || headerValue === 'uberEatsDeliveryEnabled') &&
        !this.isEnabledUber()
      ) {
        return false;
      }
      if ((headerValue === 'woltVisibility' || headerValue === 'woltActive') && !this.isEnabledWolt()) {
        return false;
      }
      if ((headerValue === 'jetTakeawayEnabled' || headerValue === 'jetDeliveryEnabled') && !this.isEnabledJet()) {
        return false;
      }
      return true;
    };

    this.sourceHeaders = (this.sourceHeaders as any[]).filter((c: ColumnHeader) => shouldIncludeHeader(c.value));
    this.headers = this.headers.filter((c: ColumnHeader) => shouldIncludeHeader(c.value));
  }

  private storeRecentArticleHeaders() {
    const headersList: ColumnHeader[] = [];

    for (const header of articleHeaders) {
      const matchingHeader = this.headers?.find((h) => h.value === header.value);

      header.default = !!matchingHeader;
      headersList.push(header);
    }

    try {
      localStorage.setItem('recentArticleHeaders', JSON.stringify(headersList));
    } catch (e) {
      console.log(e);
    }
  }

  private getRecentArticleHeaders() {
    if (!storageAvailable()) {
      return;
    }

    let storedHeaders: string | null = '';
    let headers: ColumnHeader[] = [];

    try {
      storedHeaders = localStorage.getItem('recentArticleHeaders');
    } catch (e) {
      console.log(e);
    }

    headers = storedHeaders && parseJSON(storedHeaders);

    return headers;
  }

  get accessibleHeaders() {
    const defaultSetOfColumns = ['isActive', 'number'];

    if (!this.recentArticleHeaders) {
      return articleHeaders.filter((c: ColumnHeader) => {
        if (
          c.value === 'isControlArticle' &&
          this.$can(Permission.ARTICLE_CONTROL_ACTIVATE) &&
          this.$can(Permission.ARTICLE_CONTROL_DEACTIVATE)
        ) {
          if (Object.values(TuRRoleType)?.includes(cookie.get('role') as TuRRoleType)) {
            c.default = false;
          }

          return true;
        }

        if (Object.values(SubwayRoleType)?.includes(cookie.get('role') as SubwayRoleType) && c.type === 'priceByType') {
          c.default = true;
        }
        if (cookie.get('role') === RoleType.FE045_OP && c.value === 'isActive') {
          c.default = true;
        }

        if (cookie.get('role') === RoleType.VENUE && defaultSetOfColumns.includes(c.value)) {
          c.default = true;
        }
        if (
          Object.values(TuRRoleType).includes(cookie.get('role') as TuRRoleType) &&
          defaultSetOfColumns.includes(c.value)
        ) {
          c.default = true;
        }
        return c.value !== 'isControlArticle';
      });
    }

    return this.recentArticleHeaders;
  }

  public onChangeSearch = _.debounce((searchTerm: any) => {
    this.$refs.vTable.resetPagination();
    this.$emit('search', { search: searchTerm });
  }, 500);

  public handleSortPriceType(event: string) {
    this.$refs.vTable.resetPagination();
    this.$emit('search', { priceSort: event });
  }

  public handleSortVisibilityType(event: string | undefined) {
    this.$refs.vTable.resetPagination();
    if (event === undefined) {
      this.$emit('search', delete this.articleFilter['filterVisible']);
      return;
    }
    this.$emit('search', { filterVisible: event });
  }

  public edit(item: Article) {
    if (this.$can(Permission.ARTICLE_UPDATE)) {
      this.$emit('edit', { id: item._id, category: item.category });
    } else {
      this.$emit('simple-edit', { id: item._id, category: item.category });
    }
  }

  public editModal(data: { header: ColumnHeader; item: any }) {
    if (data.item) {
      this.editDialog.isVisible = true;
      this.editDialog.name = data.header.value;
      this.editDialog.original = { ...data.item };
      this.editDialog.value = this.getEditDialogValue(data.header.value, data.item);
      this.editDialog.cell = data.header.value;
    }
  }

  private getEditDialogValue(headerValue: string, item: any): any {
    const localized = (value: string) => this.$options.filters!.localized(value);
    const decimal = (value: any) => value?.$numberDecimal || value || '';

    if (Object.values(ExternalOrderProvider)?.includes(headerValue as ExternalOrderProvider)) {
      const price = (item as Article).externalOrderProviderSettings?.find((i) => i.provider === headerValue)?.price;
      return decimal(price ?? 0);
    }

    const valueHandlers: { [key: string]: (value: any) => any } = {
      name: localized,
      description: localized,
      price: decimal,
      taxTakeaway: decimal,
      taxInside: decimal,
      'priceByType.preorder.delivery': (item) => decimal(item?.priceByType?.preorder?.delivery),
      'priceByType.preorder.takeAway': (item) => decimal(item?.priceByType?.preorder?.takeAway),
    };

    const handler = valueHandlers[headerValue];
    return handler ? handler(item[headerValue]) : item[headerValue];
  }

  public taxTakeawayToPercent(tax: { $numberDecimal: number }) {
    if (tax && tax?.$numberDecimal?.toString() === '-1') {
      return '';
    }
    if (tax && tax.$numberDecimal) {
      const taxNumber = Number(tax.$numberDecimal);
      return `${decimalAdjust('round', taxNumber * 100, -1)}%`;
    }
    return '';
  }

  get taxes() {
    return [
      { value: '0.21', text: '21%' },
      { value: '0.20', text: '20%' },
      { value: '0.19', text: '19%' },
      { value: '0.16', text: '16%' },
      { value: '0.15', text: '15%' },
      { value: '0.12', text: '12%' },
      { value: '0.10', text: '10%' },
      { value: '0.085', text: '8.5%' },
      { value: '0.081', text: '8.1%' },
      { value: '0.07', text: '7%' },
      { value: '0.077', text: '7.7%' },
      { value: '0.055', text: '5.5%' },
      { value: '0.026', text: '2.6%' },
      { value: '0.025', text: '2.5%' },
      { value: '0.021', text: '2.1%' },
      { value: '0', text: '0%' },
      { value: '-1', text: 'default' },
    ];
  }

  public saveCellValue() {
    const { name, value, original } = this.editDialog;

    if (Object.values(ExternalOrderProvider).includes(name)) {
      const { externalOrderProviderSettings } = original || {};

      const updatedSettings =
        externalOrderProviderSettings?.map((item: ExternalOrderProvidersArticleSettings) =>
          item.provider === name ? { ...item, price: value } : item,
        ) || [];

      const providerExists = externalOrderProviderSettings?.some(
        (item: ExternalOrderProvidersArticleSettings) => item.provider === name,
      );

      if (!providerExists) {
        updatedSettings.push({ provider: name, price: value });
      }

      this.$emit('editedExternalOrderProvider', { original, update: updatedSettings });
      this.stopEditing();
      return;
    }

    const data = {
      original,
      field: name,
      value,
    };

    switch (name) {
      case 'price':
        this.updatePriceField(data);
        break;

      case 'name':
      case 'description':
        this.updateI18nField(data, name);
        break;
    }

    this.$emit('edited', data);
    this.stopEditing();
  }

  private updatePriceField(data: any) {
    const arr = ['value'];
    arr.forEach((type) => {
      const res = this.deepSearchItems(data, type, (k: any, v: any) => v?.includes?.(','));
      res.forEach((obj: any) => {
        obj[type] = obj[type].replaceAll(',', '.');
      });
    });
  }

  private updateI18nField(data: any, field: string) {
    const original = { ...this.editDialog.original[field] };
    original[i18n.locale] = this.editDialog.value;
    data.value = original;
  }

  public stopEditing() {
    this.editDialog.isVisible = false;
    this.editDialog.value = null;
    this.editDialog.original = null;
    this.editDialog.name = '';
  }

  public handleAsset(event: { venue: Venue; asset: string }) {
    if (event.venue && event.asset) {
      this.updateAsset(event.venue, event.asset);
    }
  }

  public optionArticle(item: Article) {
    this.$emit('optionArticle', { id: item._id, category: item.category });
  }

  public canToggleControlArticle(val: boolean) {
    if (val) {
      return this.$can(Permission.ARTICLE_CONTROL_DEACTIVATE);
    }
    return this.$can(Permission.ARTICLE_CONTROL_ACTIVATE);
  }

  public canToggleActivation(val: boolean) {
    if (val) {
      return this.$can(Permission.ARTICLE_DEACTIVATE);
    }
    return this.$can(Permission.ARTICLE_ACTIVATE);
  }

  public canToggleVisibility(val: boolean) {
    if (val) {
      return this.$can(Permission.ARTICLE_VISIBLE);
    }
    return this.$can(Permission.ARTICLE_HIDE);
  }

  public getCategoryNameById(article: Article): any {
    const foundCategory = this.categories.find((item: Category) => item._id === article.category);
    return foundCategory ? foundCategory.name : undefined;
  }

  public filterTagsByIds(tags: string[]) {
    const filterTags = this.tags.filter((tag: Tag) => !!tags?.find((tagId: string) => tagId === tag._id));
    return filterTags
      .map((tag: Tag) => {
        return this.$options.filters!.localized(tag.name);
      })
      .toString();
  }

  public isEnabledWolt() {
    return !!this.venue?.externalOrderProviders?.wolt?.enabled;
  }
  public isEnabledUber() {
    return !!this.venue?.externalOrderProviders?.uberEats?.enabled;
  }
  public isEnabledJet() {
    return !!this.venue?.externalOrderProviders?.jet?.enabled;
  }

  private updateProviderSettings(
    item: Article,
    providerName: ExternalOrderProvider,
    key: string,
    initialValue = false,
  ) {
    const externalOrderProviderSettings =
      item?.externalOrderProviderSettings?.map((provider) => {
        if (provider.provider === providerName) {
          return {
            ...provider,
            [key]: key in provider ? !provider[key as ExternalOrderProviderKeys] : initialValue,
          };
        }
        return provider;
      }) || [];

    if (externalOrderProviderSettings.findIndex((p) => p.provider === providerName) === -1) {
      externalOrderProviderSettings.push({ provider: providerName, [key]: initialValue });
    }

    this.$emit('editedExternalOrderProvider', { original: item, update: externalOrderProviderSettings });
  }

  public getProviderValue(item: Article, providerName: ExternalOrderProvider, key: string) {
    if (!item.externalOrderProviderSettings) return false;

    const provider = item.externalOrderProviderSettings.find((i) => i.provider === providerName);

    if (!(key in (provider || {}))) {
      return true;
    }

    return provider?.[key as ExternalOrderProviderKeys];
  }

  @Watch('category')
  @Emit('category')
  public onCategoryChange() {
    this.search = '';
    this.$refs.vTable.resetPagination();
    return this.category ? this.category : null;
  }

  @Watch('categories')
  public onCategoriesChange() {
    if (this.category || this.categories.length === 0) {
      return;
    }
  }

  @Watch('venue')
  public async onVenueChange() {
    if (!this.venue) return;
    this.$refs.vColumnSelect.resetSelectedHeaders();

    this.sourceHeaders = this.accessibleHeaders;
    this.headers = (this.accessibleHeaders as ColumnHeader[]).filter((c: ColumnHeader) => c.default);
    this.serializeHeaders();
  }

  public resetTable() {
    this.category = null;
    this.$refs.vTable.resetPagination();
  }
}
