
import { Component, Prop } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import FoodcardTestingApiService from '@/api/http/FoodcardTestingApiService';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';

const api = new FoodcardTestingApiService();

@Component({
  components: { VFormBuilder },
})
export default class TestFoofcardModal extends mixins(StackedForm, Notification) {
  @Prop({ type: String, required: true }) public venueId!: string;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { getData: () => any };
  };

  get items() {
    return [
      {
        name: 'chunkSize',
        type: InputType.Text,
        label: 'article.testFoodcardModal.chunkSize',
        rules: `required|greater_eq_than:1`,
      },
    ];
  }

  public close() {
    this.$emit('cancel');
  }

  public async save() {
    if (await this.$refs.form.validate()) {
      this.$startLoading('foodcard.test');

      const data = this.$refs.form.getData();

      try {
        await api.testFoodcard({ venue: this.venueId, ...data });

        this.$stopLoading('foodcard.test');
        this.notifySuccess('article.testFoodcardModal.success');
        this.close();
      } catch (error) {
        this.$stopLoading('foodcard.test');
        this.notifyError('article.testFoodcardModal.error');
      }
    } else {
      this.notifyError('notification.form');
    }
  }
}
