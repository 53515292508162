import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';

export default class FoodcardTestingApiService extends ApiService<any> {
  constructor() {
    super('foodcard-testing', 'v1');
  }

  public testFoodcard(payload: { venue: string; chunkSize: number }): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/`, payload);
  }
}
