
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import ArticleTable from '@/components/article/ArticleTable.vue';
import { Category } from '@/interfaces/models/Category';
import { Article as ArticleModel } from '@/interfaces/models/Article';
import FoodcardApiService from '@/api/http/FoodcardApiService';
import { AxiosResponse } from 'axios';
import { toUrl } from '@/util/helper';
import Filter from '@/interfaces/api/Filter';
import { Page } from '@/interfaces/api/Page';
import EditArticleModal from '@/views/article/EditArticleModal.vue';
import EditSimpleArticleModal from '@/views/article/EditSimpleArticleModal.vue';
import PanicForm from '@/components/venue/form/PanicForm.vue';
import CopyArticlesDialog from '@/components/article/CopyArticlesDialog.vue';
import HelloTessDialog from '@/components/article/HelloTessDialog.vue';
import GastrofixDialog from '@/components/article/GastrofixDialog.vue';
import SimphonyDialog from '@/components/article/SimphonyDialog.vue';
import GeneralImportDialog from '../../components/article/GeneralImportDialog.vue';
import TestFoodcardModal from './TestFoodcardModal.vue';
import EditOptionArticleModal from './EditOptionArticleModal.vue';
import { Tag } from '@/interfaces/models/Tag';
import { Permission } from '@/enums/Permission';
import SyncExternalFoodcardsButton from '@/components/article/SyncExternalFoodcardsButton.vue';

const foodcard = namespace('foodcard');
const venue = namespace('venue');
const tag = namespace('tag');

@Component({
  components: {
    SyncExternalFoodcardsButton,
    GeneralImportDialog,
    SimphonyDialog,
    GastrofixDialog,
    HelloTessDialog,
    PanicForm,
    ArticleTable,
    Article,
    VWrapper,
    EditArticleModal,
    EditSimpleArticleModal,
    CopyArticlesDialog,
    TestFoodcardModal,
    EditOptionArticleModal,
  },
})
export default class Article extends Vue {
  public $refs!: {
    articleTable: InstanceType<typeof ArticleTable> & { articleTable: any };
    copyArticlesDialog: InstanceType<typeof CopyArticlesDialog> & { copyArticlesDialog: any };
    helloTessDialog: InstanceType<typeof CopyArticlesDialog> & { helloTessDialog: any };
    gastrofixDialog: InstanceType<typeof CopyArticlesDialog> & { gastrofixDialog: any };
    simphonyDialog: InstanceType<typeof CopyArticlesDialog> & { simphonyDialog: any };
    generalImportDialog: InstanceType<typeof CopyArticlesDialog> & { generalImportDialog: any };
    testFoodcardModal: InstanceType<typeof TestFoodcardModal>;
    editOptionArticleModal: InstanceType<typeof EditOptionArticleModal>;
  };

  get selectableCategories() {
    return this.categories.map((i: Category) => {
      return {
        value: i._id,
        text: i.name.de,
      };
    });
  }
  @venue.State('active') public venue!: Venue;

  @foodcard.Action('setCategoryFilter') public setCategoryFilter!: any;
  @foodcard.Action('setArticleFilter') public setArticleFilter!: any;
  @foodcard.Action('makeArticleVisible') public makeArticleVisible!: any;
  @foodcard.Action('hideArticle') public hideArticle!: any;
  @foodcard.Action('updateArticle') public updateArticle!: any;
  @foodcard.Action('destroyArticle') public destroyArticle!: any;
  @foodcard.Action('activateArticle') public activateArticle!: any;
  @foodcard.Action('deactivateArticle') public deactivateArticle!: any;
  @foodcard.Action('makeControlArticle') public makeControlArticle!: any;
  @foodcard.Action('undoControlArticle') public undoControlArticle!: any;
  @foodcard.Action('fetchCategories') public getCategories!: any;
  @foodcard.Action('copyArticle') public copyArticle: any;
  @foodcard.Action('fetchArticles') public getArticles!: any;
  @foodcard.Action('searchArticles') public searchArticles!: any;
  @foodcard.State((state) => state.articlePagination.total) public total!: number;
  @foodcard.State('articleFilter') public articleFilter!: Filter;

  @tag.State('items') public tags!: Tag[];
  @tag.Action('setFilter') public setTagFilter!: (f: Filter) => void;
  @tag.Action('fetch') public getTags!: any;

  public copyDialog: { visible: boolean; value: null | ArticleModel; category: string | null } = {
    visible: false,
    value: null,
    category: null,
  };

  public showEditArticleModal: boolean = false;
  public showSimpleEditArticleModal: boolean = false;
  public showCopyArticlesModal: boolean = false;
  public showTestFoodcardModal: boolean = false;
  public showEditOptionArticleModal: boolean = false;

  public articleModalProp = {
    categoryId: '',
    articleId: '',
    editing: false,
  };

  public EditOptionArticleModalProp = {
    articleId: '',
    categoryId: '',
  };

  public articleSimpleModalProp = {
    categoryId: '',
    articleId: '',
  };

  @foodcard.State('categories') protected categories!: Category[];
  @foodcard.State('articles') protected items!: ArticleModel[];

  public async mounted(): Promise<void> {
    this.fetchCategories();
    this.fetchTags();
  }

  public async doCopyArticle() {
    if (this.copyDialog.value && this.copyDialog.value.category) {
      await this.copyArticle({
        category: this.copyDialog.value.category,
        id: this.copyDialog.value!._id,
        newId: this.copyDialog.category,
      });
      this.resetCopyModal();
    }
  }

  public showCopyModal(item: ArticleModel) {
    this.copyDialog.value = item;
    this.copyDialog.visible = true;
  }

  public resetCopyModal() {
    this.copyDialog.value = null;
    this.copyDialog.visible = false;
    this.copyDialog.category = null;
  }

  public async destroy(art: ArticleModel) {
    this.$startLoading('article');
    await this.destroyArticle({ id: art._id, category: art.category });
    this.$stopLoading('article');
  }

  public updateProp(data: { original: ArticleModel; field: string; value: string }) {
    if (
      data.field === 'price' &&
      (data.original.priceIsLocked || (data.original.priceLockByType && data.original.priceLockByType.standard))
    ) {
      return;
    }

    const update: any = {};
    update[data.field] = data.value;
    this.updateArticle({ id: data.original._id, category: data.original.category, ...update });
  }

  public async updateExternalOrderProvider(data: { original: ArticleModel; update: any }) {
    this.$startLoading('article');
    await this.updateArticle({
      id: data.original._id,
      category: data.original.category,
      externalOrderProviderSettings: data.update,
    });
    this.$stopLoading('article');
  }

  public venueExists() {
    return !!(this.venue && this.venue._id);
  }

  public async onSearch(queries: any) {
    this.setArticleFilter({ ...this.articleFilter, ...queries });
    await this.fetchArticles();
  }

  public async onPageChange(page: Page) {
    this.setArticleFilter({ ...this.articleFilter, ...{ limit: page.limit } });
    await this.fetchArticles(page.page);
  }

  public async onCategoryChange(category: string) {
    this.setArticleFilter({ category, limit: this.articleFilter.limit });
    /*if (this.$isLoading('article')) {
      return;
    }*/
    if (this.categories.length) {
      this.$startLoading('article');
      await this.searchArticles({ page: 1, venue: this.venue._id });
      this.$stopLoading('article');
    }
  }

  @Watch('venue')
  public async onVenueChange() {
    this.$nextTick(() => {
      this.$refs.articleTable.resetTable();
    });
    await this.fetchCategories();
    if (this.categories[0] && this.articleFilter.limit) {
      this.setArticleFilter({ /*category: this.categories[0]._id,*/ limit: this.articleFilter.limit });
      await this.fetchArticles();
    }
    await this.fetchTags();
  }

  public async fetchCategories() {
    if (!this.venueExists() || this.$isLoading('category') || !this.$can(Permission.CATEGORY_VIEW)) {
      return;
    }
    if (this.venue && this.venue._id) {
      this.setCategoryFilter({ venue: this.venue._id });
      this.$startLoading('category');
      await this.getCategories();
      this.$stopLoading('category');
    }
  }

  public async fetchTags() {
    if (!this.venueExists() || this.$isLoading('tags') || !this.$can(Permission.TAG_VIEW)) {
      return;
    }
    try {
      this.$startLoading('tags');
      this.setTagFilter({ venue: this.venue._id });
      await this.getTags();
    } finally {
      this.$stopLoading('tags');
    }
  }

  public async fetchArticles(page: number = 1) {
    if (!this.venueExists() || this.$isLoading('article')) {
      return;
    }
    this.$startLoading('article');
    await this.searchArticles({ page, venue: this.venue._id });
    this.$stopLoading('article');
  }

  public async toggleVisibility({ item, value }: { item: ArticleModel; value: boolean }) {
    this.$startLoading('article');
    if (value) {
      await this.hideArticle({ venue: this.venue._id, articles: [item._id] });
    } else {
      await this.makeArticleVisible({ venue: this.venue._id, articles: [item._id] });
    }
    this.$stopLoading('article');
  }

  public async toggleControl({ item, value }: { item: ArticleModel; value: boolean }) {
    this.$startLoading('article');
    if (value) {
      await this.undoControlArticle({ venue: this.venue._id, articles: [item._id] });
    } else {
      await this.makeControlArticle({ venue: this.venue._id, articles: [item._id] });
    }
    this.$stopLoading('article');
  }

  public async toggleActivation({ item, value }: { item: ArticleModel; value: boolean }) {
    this.$startLoading('article');
    if (value) {
      await this.deactivateArticle({ venue: this.venue._id, articles: [item._id] });
    } else {
      await this.activateArticle({ venue: this.venue._id, articles: [item._id] });
    }
    this.$stopLoading('article');
  }
  public async exportCsvFoodcard() {
    const api: FoodcardApiService = new FoodcardApiService();
    this.$startLoading('foodcard');

    const res: AxiosResponse<BlobPart> = await api.exportFoodcard({ id: this.venue._id });

    if (res.data) {
      toUrl(res.data, `foodcard-${this.venue.name}.csv`);
    }
    this.$stopLoading('foodcard');
  }

  public async exportCsvDeFoodcard() {
    const api: FoodcardApiService = new FoodcardApiService();
    this.$startLoading('foodcard');

    const res: AxiosResponse<BlobPart> = await api.exportCsvFoodcard({ id: this.venue._id });

    if (res.data) {
      toUrl(res.data, `foodcard-${this.venue.name}.csv`);
    }
    this.$stopLoading('foodcard');
  }

  public async exportXlsxDeFoodcard() {
    const api: FoodcardApiService = new FoodcardApiService();
    this.$startLoading('foodcard');

    const res: AxiosResponse<BlobPart> = await api.exportXlsxFoodcard({ id: this.venue._id });

    if (res.data) {
      toUrl(res.data, `foodcard-${this.venue.name}.xlsx`);
    }
    this.$stopLoading('foodcard');
  }

  public openEditModal(article: { id: string; category: string }) {
    this.articleModalProp.articleId = article.id;
    this.articleModalProp.categoryId = article.category;
    this.articleModalProp.editing = true;
    this.showEditArticleModal = true;
  }

  public openTestFoodcardModal() {
    this.showTestFoodcardModal = true;
  }

  public openOptionArticleModal(article: { _id: string; category: string }) {
    this.EditOptionArticleModalProp.articleId = article._id;
    this.EditOptionArticleModalProp.categoryId = article.category;
    this.showEditOptionArticleModal = true;
  }

  public closeEditModal(payload?: any) {
    if (payload && payload.updateList) {
      this.fetchArticles();
    }
    this.showEditArticleModal = false;
  }

  public closeTestFoodcardModal() {
    this.showTestFoodcardModal = false;
  }

  public closeEditOptionArticleModal() {
    this.showEditOptionArticleModal = false;
  }

  public openSimpleEditModal(article: { id: string; category: string }) {
    this.articleSimpleModalProp.articleId = article.id;
    this.articleSimpleModalProp.categoryId = article.category;
    this.showSimpleEditArticleModal = true;
  }

  public closeSimpleEditModal() {
    this.showSimpleEditArticleModal = false;
  }

  public openCopyArticlesModal() {
    this.$refs.copyArticlesDialog.setShowModal();
  }

  public openHelloTessDialog() {
    this.$refs.helloTessDialog.setShowModal(true);
  }

  public openGastrofixDialog() {
    this.$refs.gastrofixDialog.setShowModal(true);
  }

  public openSimphonyDialog() {
    this.$refs.simphonyDialog.setShowModal(true);
  }

  public openGeneralImportDialog() {
    this.$refs.generalImportDialog.setShowModal(true);
  }
}
